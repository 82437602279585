import React from 'react';
import Carousel from '../components/Carousel';

class CarouselPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  handleNextButtonClick = () => {
    this.setState(currentState => ({
      ...currentState,
      index: currentState.index + 1,
    }));
  };

  handlePrevButtonClick = () => {
    this.setState(currentState => ({
      ...currentState,
      index: currentState.index - 1,
    }));
  };

  render() {
    const { index } = this.state;
    return (
      <div>
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
          }}
        >
          <button type="button" onClick={this.handlePrevButtonClick}>
            Prev
          </button>
          <button type="button" onClick={this.handleNextButtonClick}>
            Next
          </button>
        </div>
        <Carousel index={index}>
          <div>1</div>
          <div>2</div>
          <div>3</div>
        </Carousel>
      </div>
    );
  }
}

export default CarouselPage;
