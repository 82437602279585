import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

const CarouselMover = ({ children, index }) => (
  <div
    className={css({
      height: '300%',
      width: '100%',
      position: 'absolute',
      transition: 'top ease 0.5s',
      top: `${-100 * index}%`,
    })}
  >
    <div
      className={css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'spread',
        flexDirection: 'column',
        height: '100%',
      })}
    >
      {children}
    </div>
  </div>
);

CarouselMover.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
};

const CarouselScoper = ({ children }) => (
  <div
    className={css({
      height: '100%',
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
    })}
  >
    {children}
  </div>
);

CarouselScoper.propTypes = {
  children: PropTypes.node.isRequired,
};

const CarouselEntry = ({ children, length }) => (
  <div
    className={css({
      flex: 1,
      width: '100%',
      height: `${100 / length}%`,
    })}
  >
    {children}
  </div>
);

CarouselEntry.propTypes = {
  children: PropTypes.node.isRequired,
  length: PropTypes.number.isRequired,
};

const Carousel = ({ index, children }) => (
  <CarouselScoper>
    <CarouselMover index={index}>
      {React.Children.map(children, child => (
        <CarouselEntry length={children.length}>{child}</CarouselEntry>
      ))}
    </CarouselMover>
  </CarouselScoper>
);

Carousel.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default Carousel;
